import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLocation } from "react-router-dom"
import { memo, useEffect } from "react"
import { allAnimations } from "../effects/Observers"

const InfoContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns:  repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10vh;
    

    @media ${device.xl} {
        width: 100vw;
    }

    @media ${device.lg} {
        margin-top: 5vh;

    }

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 7vh;

    }
`


const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background-color: red;

    grid-column: 1 / 3;

    @media ${device.md} {
        display: none;
    }
`

const Card = styled.div`
    height: fit-content;
    padding: 20vh 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background-color: #fff;

    grid-column: 3 / 5;

    @media ${device.lg} {
        padding: 10vh 10vw;
    }

    @media ${device.md} {
        grid-column: 1 / 5;
    }

`

const Title = styled.h2`
    width: 100%;
    font-size: 34px;
    color: #fff;
    
    @media ${device.md} {
        font-size: 28px;
    }

    @media ${device.sm} {
        font-size: 24px;
        text-align: center !important;
    }

    @media ${device.xs} {
        font-size: 18px;
    }

`

const TextInfo = styled.p`
    width: 100%;
    color: #fff;
    line-height: 25px;
    margin: 30px 0px;
    max-width: 450px;
    z-index: 1;

    @media ${device.lg} {
        font-size: 14px;
    }
    
    @media ${device.md} {
        max-width: 80%;
        font-size: 14px;
    }

    @media ${device.sm} {
        max-width: unset;
        font-size: 12px;
        text-align: center !important;
    }
`

const Abertura04 = styled.img`
    height: 150px;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 10%;
    top: 12%;
    margin: auto;

    @media ${device.xl} {
        height: 100px;
    }

    @media ${device.lg} {
        height: 80px;
        left: 5%;

    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura05 = styled.img`
    height: 50px;
    width: auto;
    z-index: 2;
    align-self: end;
    margin-top: 10px;

    @media ${device.sm} {
        height: 40px;
        align-self: center;
    }
`

const Abertura06 = styled.img`
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 20%;
    right: 0px;
    top: 0px;
    margin: auto;
    transform: scaleX(-1);

    @media ${device.xl} {
        left: 7%;
    }

    @media ${device.lg} {
        left: 0%;
    }

    @media ${device.md} {
  
    }
`


const HerrajesIntroComponent = () => {

    const location = useLocation();

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    return (

        <InfoContainer
        >

            <ImageContainer >

                <Abertura04
                    className="utils__fade-in-fast-top"
                    src={require('../images/roto-blanco.png')}
                    alt="abertura"
                />

                <Abertura06

                    src={require('../images/roto-patio.png')}
                    alt="abertura"
                />


            </ImageContainer>

            <Card>

                <Title
                    className="utils__fade-in-fast-top"
                    style={{
                        textAlign: 'end',
                        color: '#111'
                    }}
                >
                    Cierres<br />Multipuntos
                </Title>

                <TextInfo
                    className="utils__fade-in-fast-top"
                    style={{
                        textAlign: 'end',
                        alignSelf: 'end',
                        color: '#111',

                    }}
                >
                    Los cierres multipuntos mejoran la seguridad y
                    aislamiento en ventanas y puertas al distribuir
                    varios puntos de cierre en el marco, garantizando
                    un ajuste hermético. Ofrecen protección contra intrusiones
                    y optimizan el confort térmico y acústico combinando
                    funcionabilidad y elegancia en un sistema
                    discreto y seguro.

                </TextInfo>

                <Abertura05
                    className="utils__fade-in-fast-top"
                    src={require('../images/german-made.png')}
                    alt="abertura"
                />

            </Card>

        </InfoContainer>

    )
}

const HerrajesIntro = memo(HerrajesIntroComponent);

export default HerrajesIntro;