import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import { JobsScreen } from "../screens/JobsScreen";
import { ProductsScreen } from "../screens/ProductsScreen";
import { ProductScreen } from "../screens/ProductScreen";
import { QuotatorScreen } from "../screens/QuotatorScreen";
import { ContactScreen } from "../screens/ContactScreen";
import { AboutScreen } from "../screens/AboutScreen";
import Header from "../components/Header";
import WsFloatButton from "../components/WsFloatButton";
import Footer from "../components/Footer";
import Rights from "../components/Rights";

export const AppRouter = () => {

    // console.warn = function () { };
    // console.error = function () { };

    return (

        <Router>

            <div className='router-content'>

                <Header />

                <WsFloatButton />

                <Switch>

                    <Route
                        exact
                        path='/'
                        component={HomeScreen}
                    />

                    <Route
                        exact
                        path='/nosotros'
                        component={AboutScreen}
                    />

                    <Route
                        exact
                        path='/productos'
                        component={ProductsScreen}
                    />

                    <Route
                        exact
                        path='/productos/:id(aluminio|pvc|herrajes)'
                        component={ProductScreen}
                    />

                    <Route
                        exact
                        path='/obras'
                        component={JobsScreen}
                    />

                    <Route
                        exact
                        path='/contacto'
                        component={ContactScreen}
                    />

                    <Route
                        exact
                        path='/cotizador'
                        component={QuotatorScreen}
                    />



                    <Redirect to='/' />

                </Switch>

                <Footer />

                <Rights brand="Aluviher" />

            </div>

        </Router>

    )
}
