import { Suspense, lazy, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ProductIntro from "../components/ProductIntro";
import { allAnimations } from "../effects/Observers"
import LoadingComponent from "../components/LoadingComponent"
import PvcColors from "../components/PvcColors";
import ProfilesSection from "../components/ProfilesSection";
import { aluarTexts, hydroTexts, pvcTexts } from "../constants/ProductConstants";
import { text } from "stream/consumers";
import HerrajesIntro from "../components/HerrajesIntro";

const ProductSection = lazy(() => import("../components/ProductSection"));

export const ProductScreen = () => {

    const location = useLocation()

    const path = location.pathname;

    const getTexts = () => {

        if (path.includes('pvc')) {
            return pvcTexts;
        }
        if (path.includes('aluminio')) {
            return hydroTexts;
        }

        return hydroTexts
    }

    const [texts] = useState(getTexts());


    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    if (path.includes('herrajes')) {
        return <HerrajesIntro />;
    }

    return (

        <>

            <ProductIntro
                title={texts?.mainSection.title}
                image={texts?.mainSection.image}
                description={texts?.mainSection.description}
                systems={texts?.mainSection.systems}
            />

            <Suspense fallback={<LoadingComponent />}>

                {
                    texts?.firstData.map((value) => {

                        return (
                            <ProductSection
                                key={value.title}
                                title={value.title}
                                subtitle={value.description}
                                image={value.image}
                            />
                        )
                    })
                }

                {
                    texts?.profiles.map((value) => {

                        return (

                            <ProfilesSection
                                title={value.title}
                                profiles={value.profiles}
                            />
                        )
                    })
                }

                {
                    texts?.lastData.map((value) => {

                        return (
                            <ProductSection
                                key={value.title}
                                title={value.title}
                                subtitle={value.description}
                                image={value.image}
                            />
                        )
                    })
                }


                <ProductSection
                    title={texts?.colors.title}
                    subtitle={texts?.colors.description}
                    image={undefined}
                    children={<PvcColors data={texts.colors.colors} />}

                />



            </Suspense>

        </>

    )
}
