import Introduction from "../components/Introduction"
import { Suspense, lazy, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import ScreenContainer from "../components/ScreenContainer"
import LoadingComponent from "../components/LoadingComponent"
import WorkModal from "../components/WorkModal";
import Descriptions from "../components/Descriptions";

const JobsGridHome = lazy(() => import("../components/JobsGridHome"));


export const HomeScreen = () => {

    const location = useLocation()

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    const [showModal, setShowModal] = useState<null | { id: number, image: number }>(null);

    return (

        <ScreenContainer>

            <Introduction />

            {showModal !== null && <WorkModal showModal={showModal} setShowModal={setShowModal} />}

            <Suspense fallback={<LoadingComponent />}>

                <Descriptions />

                <JobsGridHome setShowModal={setShowModal} />

            </Suspense>

        </ScreenContainer>

    )
}
