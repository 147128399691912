import { ContactFormProps, QuotatorFormProps } from "../interfaces/ContactInterfaces";
import { BrandsCarrouselProps, IntroImagesProps, ProfileProps, PvcColorProps, WorkProps } from "../interfaces/WorkInterfaces";

export const introImagesList: IntroImagesProps[] = [
    {
        id: 1,
        title: 'CALIDAD Y DISEÑO',
        subtitle: 'PARA TU PROYECTO',
        textColor: '#ff0c19',
        image: require('../images/intro1.webp')
    },
    {
        id: 2,
        title: 'MAS DE 25 AÑOS',
        subtitle: 'DE EXPERIENCIA',
        textColor: '#ff0c19',
        image: require('../images/intro2.webp'),
    },
    {
        id: 3,
        title: 'LA SEGURIDAD',
        subtitle: 'PARA TU HOGAR',
        textColor: '#ff0c19',
        image: require('../images/intro3.webp'),
    },
    {
        id: 4,
        title: 'ABERTURAS DE ',
        subtitle: 'ALUMINIO Y PVC',
        textColor: '#ff0c19',
        image: require('../images/intro4.webp'),
    },
]

export const initialContactForm: ContactFormProps = {
    message: '',
}

export const initialQuotatorForm: QuotatorFormProps = {
    name: '',
    email: '',
    phone: '',
    locality: '',
    workStatus: 'Proyecto',
    message: '',
}

export const fieldIncompleteContactForm = {
    message: false,
}

export const fieldIncompleteQuotatorForm = {
    name: false,
    email: false,
    phone: false,
    locality: false,
}

export const initialStateWork: WorkProps = {
    id: 0,
    name: '',
    goTo: '',
    logEvent: '',
    images: []
}


export const worksList: WorkProps[] = [
    {
        id: 0,
        name: 'Golf Nordelta',
        goTo: '/works/#golfnordelta',
        logEvent: 'click_obra_golf_nro_1',
        images: [
            require('../images/job1.webp'),
        ]
    },
    {
        id: 1,
        name: 'Newman',
        goTo: '/works/#newman',
        logEvent: 'click_obra_nro_2',
        images: [
            require('../images/job2.webp'),
        ]
    },
    {
        id: 2,
        name: 'Casa Newman',
        goTo: '/works/#casanewman',
        logEvent: 'click_obra_casa_nro_3',
        images: [
            require('../images/job3.webp'),
        ]
    },
    {
        id: 3,
        name: 'Albanueva',
        goTo: '/works/#albanueva',
        logEvent: 'click_obra_nro_4',
        images: [
            require('../images/job4.webp')
        ]
    },
    {
        id: 4,
        name: 'Casav',
        goTo: '/works/#casav',
        logEvent: 'click_obra_nro_5',
        images: [
            require('../images/job5.webp'),
        ]
    },
    {
        id: 5,
        name: '',
        goTo: '/works/#casav',
        logEvent: 'click_obra_nro_6',
        images: [
            require('../images/job6.webp'),
        ]
    },
    ////////////////////////////////////////////
    {
        id: 6,
        name: '',
        goTo: '/works/#casa1',
        logEvent: 'click_obra_nro_7',
        images: [
            require('../images/job7.webp'),
        ]
    },
    {
        id: 7,
        name: '',
        goTo: '/works/#casa2',
        logEvent: 'click_obra_nro_8',
        images: [
            require('../images/job8.webp'),
        ]
    },
    {
        id: 8,
        name: '',
        goTo: '/works/#casa4',
        logEvent: 'click_obra_nro_9',
        images: [
            require('../images/job10.webp'),
        ]
    },
    {
        id: 9,
        name: '',
        goTo: '/works/#casa5',
        logEvent: 'click_obra_nro_10',
        images: [
            require('../images/job11.webp'),
        ]
    },
    {
        id: 10,
        name: '',
        goTo: '/works/#casa6',
        logEvent: 'click_obra_nro_11',
        images: [
            require('../images/job12.webp'),
        ]
    },
    {
        id: 11,
        name: '',
        goTo: '/works/#casa7',
        logEvent: 'click_obra_nro_12',
        images: [
            require('../images/job13.webp'),
        ]
    },
    {
        id: 12,
        name: '',
        goTo: '/works/#casa8',
        logEvent: 'click_obra_nro_13',
        images: [
            require('../images/job14.webp'),
        ]
    },
    {
        id: 13,
        name: '',
        goTo: '/works/#casa9',
        logEvent: 'click_obra_nro_14',
        images: [
            require('../images/job15.webp'),
        ]
    },
    {
        id: 14,
        name: '',
        goTo: '/works/#casa10',
        logEvent: 'click_obra_nro_15',
        images: [
            require('../images/job16.webp'),
        ]
    },
    {
        id: 15,
        name: '',
        goTo: '/works/#casa11',
        logEvent: 'click_obra_nro_16',
        images: [
            require('../images/job17.webp'),
        ]
    },
    // {
    //     id: 16,
    //     name: '',
    //     goTo: '/works/#casa11',
    //     logEvent: 'click_obra_nro_16',
    //     images: [
    //         require('../images/job9.webp'),
    //     ]
    // },
]


export const BrandsCarrouselList: BrandsCarrouselProps[] = [
    {
        id: 0,
        image: require('../images/rehau-blanco.png'),
    },
    {
        id: 1,
        image: require('../images/hydro-blanco.png'),
        customStyle: {
            height: 80,
            width: 80,
        }
    },
    {
        id: 2,
        image: require('../images/aluar-blanco.png'),
        customStyle: {
            height: 70,
            width: 70,
        }
    },
    {
        id: 3,
        image: require('../images/ok-industrial.png'),
        customStyle: {
            height: 60,
            width: 60,
        }
    },
    {
        id: 4,
        image: require('../images/disvial.png'),
    },
    {
        id: 5,
        image: require('../images/gu.png'),
        customStyle: {
            height: 70,
            width: 70,
        }
    },
    {
        id: 6,
        image: require('../images/siegenia.png'),
    },
    {
        id: 7,
        image: require('../images/rial.png'),
        customStyle: {
            height: 80,
            width: 80,
        }
    },
    {
        id: 8,
        image: require('../images/axal.png'),
    },
    {
        id: 9,
        image: require('../images/bestchem.png'),
    },
    {
        id: 10,
        image: require('../images/roto.png'),
    }
]