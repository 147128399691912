import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { memo } from "react"
import Counter from "./Counter"

const Container = styled.div`
    width: 80%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 120px 0px 150px;
    align-self: center;

    @media ${device.lg} {
        width: 90%;
    }

    @media ${device.md} {
        width: 80%;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: center;
    position: relative;
    gap: 100px;

    @media ${device.xl} {
        gap: 50px;
    }

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }
`

const DataContainer = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    border-top: 2px solid #ccc;
    padding: 30px 0px;

    @media ${device.md} {
        padding: 30px 0px 0px;
        align-items: center;
    }

`

const DataTitle = styled.p`
    color: #222;
    font-size: 72px;
    font-weight: 500;
    
    @media ${device.md} {
        text-align: center;
        font-size: 60px;
    }

    @media ${device.sm} {
        font-size: 50px;
    }
`

const TextInfo = styled.p`
    color: #222;
    line-height: 25px;
    margin-bottom: 15px;

    @media ${device.lg} {
        font-size: 14px;
    }
    
    @media ${device.md} {
        text-align: center;
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 60px;

    h2 {
        width: 100%;
        font-size: 38px;
        font-weight: 600;
        color: #2E2D2D;
        text-align: start;

        @media ${device.md} {
            font-size: 28px;
            text-align: center;

        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }
    
    @media ${device.sm} {
        margin-bottom: 50px;
    }

`

const DescriptionsComponent = () => {

    return (

        <Container>

            <Title className="utils__fade-in-fast-top">
                <h2>
                    Fabricantes especializados
                    <br />
                    Aberturas de Alta
                    <br />
                    Tecnología
                </h2>
            </Title>


            <ContentContainer>

                <DataContainer className="utils__fade-in-fast-top">

                    <Counter targetNumber={250} />

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{ fontWeight: 600 }}
                    >
                        Proyectos realizados
                    </TextInfo>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                    >
                        Hemos completado con éxito más de 250 proyectos, brindando soluciones a medida para cada uno de nuestros clientes.
                        Estos proyectos abarcan desde residencias particulares hasta grandes desarrollos comerciales, siempre con un enfoque
                        en la calidad y el diseño moderno.
                    </TextInfo>

                </DataContainer>
                <DataContainer className="utils__fade-in-fast-top">

                    <Counter targetNumber={25} />

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{ fontWeight: 600 }}
                    >
                        Años de experienca
                    </TextInfo>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                    >
                        Con años de experiencia en el sector se ha consolidado como una empresa de referencia en la fabricación de Aberturas
                        en PVC REHAU y Aluminio Hydro. A lo largo de nuestra trayectoria, hemos adoptado tecnología de vanguardia y nos
                        especializamos en soluciones que priorizan la eficiencia energética, calidad y durabilidad.
                    </TextInfo>

                </DataContainer>

                <DataContainer className="utils__fade-in-fast-top">

                    <Counter targetNumber={15} />

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{ fontWeight: 600 }}
                    >
                        Miembros del equipo
                    </TextInfo>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                    >
                        Nuestro propio equipo está compuesto por profesionales altamente capacitados y comprometidos en la fabricación de aberturas.
                        Gracias a su experiencia y dedicación, ofrecemos soluciones innovadoras y personalizadas para cada proyecto, adaptandonos a
                        las necesidades de nuestros clientes.
                    </TextInfo>

                </DataContainer>

            </ContentContainer>

        </Container>

    )
}

const Descriptions = memo(DescriptionsComponent);

export default Descriptions;